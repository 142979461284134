div {
  margin: auto;
}

.image-logo {
  width: min(100%, 600px);
  display: block;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .image-logo {
    width: 90%;
  }
}
.nav-bar-top {
  color: #0c3787;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.nav-bar-top {
  cursor: pointer;

}

.nav-bar-router {
  display: grid;
  grid-template-columns: repeat(auto-fit, min(100%, 150px));
  gap: 10px;
  overflow-x: hidden;
  justify-content: center;
}

.nav-bar-element {
  padding: 5px;
  width: 100%;
  text-align: center;
}

.nav-bar-bottom {
  background-color: #0c3787;
  height: 5px;
  margin-bottom: 5px;
}

.login-base {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  align-items: center;
  float: left;
  justify-items: center;
  justify-content: center;
  margin-top: 10px;
}

.login-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

label {
 color: #757575;
 font-weight: bold;
 text-align:left;
 width: 90%;
 font-size: 14px;
 margin-top: 15px;
 margin-bottom: 10px;
}

.log-cont {
  width: 90%;
  font-size: 14px;
}
.regist-input {
 max-width: 400px;
 width: 350px;
}

.btn {
  border: none;
  width: 90%;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
  color:white;
  background-color: #01bf2d;
  margin-top: 30px;
  
}

.label-login {
  color:#0c3787;
}

.login-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.image-login-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
}
.iconos-login {
  width: 45%;
}


.images-login {
  width: 190px;
  height: 190px;
  overflow: hidden;
  
}

.images-login img {
  width: 190px;
  height: 190px;
  gap: 30px;
  overflow-y: hidden;
}


.regist {
  display: grid;
  grid-template-columns: repeat(auto-fit, min(100%, 800px));
  gap: 50px;
  margin: 30px;

}
.logopepe {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .logopepe {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}



submit {
  max-width: 75%;
}


.gray-square {
  border: 1px ridge;
  margin: 5px;
  padding: 5px;
}

.grid-courses {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 300px));
  gap: 10px;
  overflow-x: hidden;
  justify-content: center;
  cursor: pointer;
}

.title-gray-square {
  font-weight: bold;
  font-size: large;
}

.description-gray-square {
  font-style: italic;
  font-size: medium;
}

.progress-gray-square {
  font-size: small;
  text-align: end;
}

.users{
  border-collapse:separate;
  border-spacing: 0.5rem;
  border: 1px solid #ddd;
}

th,td {
  text-align: left;
  padding: 8px;
}

.header{
  margin-left: 35%;
  color: black;
  font-size: 3rem;
  font-weight: bolder;
}

.btn-crear{
  display: grid;
  margin-left: 1.5%;
  padding: 1px;
  min-width: 50px;
  max-width: 200px;
  margin-bottom: 5px;
}

.checkbox{
  padding-left: 5%;
  float: left;
  width: auto;
}

.modal{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 500px;
  background-color: white;

}
.modal-header, .modal-footer {
  padding: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

.logo-pepe {
  width: min(100%, 500px);
  display: block;
  margin: auto;
}

.logo-pepe2 {
  width: min(100%, 100px);
  display: block;
  margin: auto;
 
}


* {
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  * {
    margin: 0;
    padding: 5px;
    
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}


.container-landing {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}


.hero h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}


.hero p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}


.btn-cta {
  background-color: #29d15b;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cta:hover {
  background-color: #1fa542;
}


.features {
  margin-top: 40px;
}

.features h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(33% - 20px);
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.card img {
    height: 200px;
    width: 300px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  
}

.card h2, .card h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.btn-card {
  background-color: #29d15b;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-card:hover {
  background-color: #1fa542;
}


.nuestra-plataforma {
  margin-top: 40px;
}

.title-nuestra h1 {
  font-size: 24px;
  font-weight: bold;
  color: #29d15b;
  margin-bottom: 20px;
  text-align: center;
}

.container-plataforma {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.container-plataforma div {
  text-align: center;
}

.container-plataforma img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.container-plataforma h1 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}


.about {
  padding: 40px;
  color: #fff;
  margin-top: 10%;
}

.about h1 {
  font-size: 36px;
  font-weight: bold;
  color: #29d15b;
  margin-bottom: 20px;
}

.about h3 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.about p {
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  margin-bottom: 20px;
}


.testimonials-container {
  margin-top: 40px;
  background-color: #f5f5f5;
  padding: 40px;
}

.testimonial-card {
  margin-bottom: 20px;
}

.testimonial-card h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.testimonial-card iframe {
  width: 100%;
  height: 315px;
  margin-bottom: 10px;
}

.testimonial-card h2, .testimonial-card h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}


.carusel {
  margin-top: 40px;
}

.carusel h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}


.foot {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.sociales h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.sociales p {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.foot p {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}



img.active {
  opacity: 1;
  width: 50%;
  height: 50%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition:linear
}



.modal-check {
  display:initial;
}
.main-content {
  width: 100%;
}

.video-player {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player iframe {
  width: 100%;
  height: 100%;
}

.description {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  max-width: 960px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}
.video-player iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9; 
}
.video-player {
  width: 100%;
  height: 0;
  padding-top: 80%; 
  position: relative;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar{
  border: 1px solid black;
  width: 100%;
  margin-top: 5%;

}


.Autoevaluacion {
  margin-left: 20px;
}


.table-price {
    max-width: 100%;
    height: auto;

 
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}


.table-price1 {
  border: 3px solid black;
  max-width: 100%;
  height: 400px;
  border-collapse: collapse;
  background-color:#ffc000;
  color: black;
  
}


@media screen and (max-width: 600px) {
  .table-price1 td,
  .table-price1 th {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .table-price1 tr {
    display: block;
    margin-bottom: 10px;
  }
  .table-price1 td,
  .table-price1 th {
    display: block;
    text-align: left;
  }
  .table-price1 td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}

@media only screen and (max-width: 600px) {
  .table-container {
    overflow-x: scroll;
  }
}


.register-price {
  border: 3px solid black;
  border-collapse: collapse;
  text-align: center;
}

.quiz-container {
  max-width: 900px;
  max-height: fit-content;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.quiz-question {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.quiz-options {
  list-style-type: none;
  padding: 0;
}

.quiz-options li {
  margin-bottom: 5px;
}

.quiz-options label {
  display: block;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-options label:hover {
  background-color: #f0f0f0;
}

.quiz-submit {
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.quiz-submit:hover {
  background-color: #0056b3;
}
.perfil {
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;
   margin: 15px;
   padding: 15px;
}
.ebooks{
  margin-bottom: 50px;
  margin-top: 50px;
}
.img-top{
  height: 200px;
  width: 200px;
}
.card-img-top {
 
  display: block;
  margin: auto;
  width: 50%;
  height: auto;
}

.container-audios-books { 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
}

.card1 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.card1:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
.card1 .img-top {
  display: block;
  margin: auto;
  width: 50%;
  height: auto;
}

.card1 .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.card1 .btn-p {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.card1 .btn-p:hover {
  background-color: #0069d9;
}

@media screen and (max-width: 767px) {
  .container-audios-books {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container-audios-books {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container-audios-books {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media screen and (min-width: 1200px) {
  .container-audios-books {
    grid-template-columns: 1fr;
  }
}


.h2-audio {
  text-align: center;
  font-size: 2rem;
  margin: 2rem 0;
}


.Quiz {
  max-width: 700px;
  height: 700px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 70px;
  justify-content: space-between;
  background-color: #fbc109;
  color: #0c3787;
}
.Quiz label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0c3787;
}
.Quiz input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.Quiz button[type="submit"] {
  background-color: #0c3787;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}


.Quiz button[type="submit"]:hover {
  background-color: #0c3787;
}

.formulario {
  max-width: 800px;
  height:fit-content;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 70px;
  justify-content: space-between;
  background-color: #fbc109;
  color: #0c3787;
}
.pregunta {
  margin-bottom: 1rem;
}

.formulario label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0c3787;
}
.formulario input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.formulario button[type="submit"] {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #0c3787;
  color: #fff;
  border: none;
  cursor: pointer;
}
.formulario button[type="submit"]:hover {
  background-color: #0c3787;
}

.modal1 {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal1-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
}

.button-modal1{
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #0c3787;
  color: #fff;
  border: none;
  cursor: pointer;
}

.button-modal1:hover {
  background-color: #0c3787;
}

.Mensaje-del-día{ 
  font-weight: bolder;
  font-size: larger;
  align-items: center;
  justify-content: center;
  display: flex;

}
.formulario-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

.formulario-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.formulario-container p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.formulario-container label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.formulario-container select {
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.formulario-container button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formulario-container button:hover {
  background-color: #3e8e41;
}

.boton123 {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #0c3787;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.boton123:hover {
  background-color: #3e8e41;
}


  .land {
    text-align: center;
    padding: 0 20px;
  }
  
  .land-header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .background-image {
    
    background-size: cover;
    background-position: center;
    height: 100vh;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  
  .slick-dots {
    bottom: 10px;
  }
  
  .slick-dots li button:before {
    color: #fff;
    font-size: 12px;
  }
  
  .Section {
    padding: 50px 0;
    text-align: left;
  }
  
  .Section h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .Section p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  .Section img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }


section {
  padding: 50px;
  background-color: #f7f7f7;
}

h2 {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  section {
    padding: 30px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.card123 {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header123 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-title123 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.card-arrow123 {
  font-size: 30px;
  color: #999;
}

.card-arrow123 span {
  cursor: pointer;
}

.card-body123 {
  padding: 15px;
  font-size: 16px;
}

.arrow::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid black;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.note {
  background-color: #0c3787;
  padding: 10px;
  border: 1px solid black;
  font-weight: bolder;
  color: white;
  margin-left: 2.1%;
  width: 90%;
  font-size: 18px;
  margin-bottom: 20px;
  overflow-x: auto;

}
.steps-list {
  display: inline-flex;
  flex-wrap: nowrap;
  padding-left: 20px;
  list-style-type: none;
  white-space: nowrap;
}

.steps-list li {
  margin-right: 10px;
}

@media (max-width: 600px) {
  .note {
    font-size: 10px;
  }
}

.note::-webkit-scrollbar {
  width: 2px;
}

.note::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 1px;
}

.note::-webkit-scrollbar-thumb:hover {
  background-color: #dcdcdc;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  padding: 50px;
  background-color: #F4F4F4;
}

.column-1, .column-2 {
  width: 48%;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-top: 50px;
}

.column-1 {
  margin-right: 20px;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

span:first-of-type {
  flex-grow: 1;
  margin-right: 10px;
}

span:last-of-type {
  font-size: 1.1rem;
  color: #666666;
}

@media screen and (max-width: 767px) {
  .dashboard-container {
    flex-direction: column;
    align-items: center;
  }
}

.column-1, .column-2 {
  width: 50%;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .column-1, .column-2 {
    width: 100%;
    padding: 10px;
  }
}

.Miscore {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 90%;
}


.input-container {
  margin-top: 20px;
  text-align: center;
}
